import React from 'react';
import { withPrefix } from 'gatsby';
import { StyleProps } from 'glamor';
import { LazyImage } from '../LazyImage';

interface ClientProps extends StyleProps {
  id: string;
  name: string;
  url: string;
}

export const Client: React.SFC<ClientProps> = ({ id, name, url, ...rest }) => (
  <a href={url} title={name} {...rest}>
    <LazyImage
      src={withPrefix(`/logos/${id}.svg`)}
      alt={name}
      width={100}
      height={100}
    />
  </a>
);
