import React from 'react';
import { css, keyframes } from 'glamor';
import { Button, ButtonProps } from './Button';
import { theme } from '../../theme';

const DURATION = 200;
const ARROW_DISTANCE = 30;

const textFillStyles = css({
  position: 'absolute',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textAlign: 'initial',
  width: 0,
  transition: `width ${DURATION}ms ease-out ${DURATION}ms`,
  '@media (hover: none)': {
    display: 'none',
  },
});

const backgroundFillStyles = css({
  position: 'absolute',
  top: 0,
  left: 0,
  width: 0,
  height: '100%',
  transition: `width ${DURATION}ms ease-in-out ${DURATION}ms`,
  '@media (hover: none)': {
    display: 'none',
  },
});

const fixedArrowStyles = css({
  position: 'absolute',
  marginLeft: '1rem',
});

const arrowStyles = css({
  position: 'absolute',
  marginLeft: '1rem',
  transform: `translateX(-${ARROW_DISTANCE}px)`,
  opacity: 0,
  transition: `all ${DURATION}ms ease-in-out`,
});

const leadArrowAnimation = keyframes({
  '0%': {
    opacity: 1,
    transform: 'translateX(0)',
  },
  '4%': {
    opacity: 1,
    transform: 'translateX(-2px)',
  },
  '17%': {
    opacity: 0,
  },
  '20%': {
    opacity: 0,
    transform: `translateX(${ARROW_DISTANCE}px)`,
  },
  to: {
    transform: `translateX(${ARROW_DISTANCE}px)`,
    opacity: 0,
  },
});

const followArrowAnimation = keyframes({
  '0%, 4%': {
    opacity: 0,
    transform: `translateX(-${ARROW_DISTANCE}px)`,
  },
  '8%': {
    opacity: 0,
  },
  '20%': {
    opacity: 1,
    transform: 'translateX(1px)',
  },
  '24%': {
    transform: 'translateX(0)',
  },
  to: {
    opacity: 1,
    transform: 'translateX(0)',
  },
});

const arrowButtonStyles = css({
  // right padding is equal to left padding + arrow left margin + arrow width
  padding: '.75rem calc(2.5rem + 10px) .75rem 1.5rem',
  ':hover': {
    [`& .${textFillStyles}`]: {
      width: '80%',
      transition: `width ${DURATION}ms ease-in ${DURATION}ms`,
    },
    [`& .${backgroundFillStyles}`]: {
      width: '100%',
      transition: `width ${DURATION}ms ease-in-out`,
    },
    [`& .${arrowStyles}`]: {
      // animation delay is 1.5s plus total transition duration
      animation: `${followArrowAnimation} 3s calc(1.5s + ${DURATION *
        2}ms) infinite`,
    },
    [`& .${arrowStyles}:last-child`]: {
      transform: 'translateX(0)',
      opacity: 1,
      transition: `all ${DURATION}ms ease-out ${DURATION * 2}ms`,
      // animation delay is 1.5s plus total transition duration
      animation: `${leadArrowAnimation} 3s calc(1.5s + ${DURATION *
        2}ms) infinite`,
    },
  },
});

export const ArrowButton = React.forwardRef<{}, ButtonProps>((props, ref) => {
  const {
    backgroundColor = theme.color.grayDarker,
    color = theme.color.white,
    children,
  } = props;

  const backgroundStyles = css({
    background: color,
  });

  const contents = (
    <>
      <span className={backgroundFillStyles.toString()} {...backgroundStyles} />
      <span
        className={textFillStyles.toString()}
        {...css({
          color: backgroundColor,
        })}
        aria-hidden={true}
      >
        {children}
      </span>
      {children}
      <span className={fixedArrowStyles.toString()} aria-hidden={true}>
        →
      </span>
      <span
        className={arrowStyles.toString()}
        {...css({
          color: backgroundColor,
        })}
        aria-hidden={true}
      >
        →
      </span>
      <span
        className={arrowStyles.toString()}
        {...css({
          color: backgroundColor,
        })}
        aria-hidden={true}
      >
        →
      </span>
    </>
  );

  const buttonColorStyles = css({
    border: `2px solid ${backgroundColor}`,
    ':hover': {
      backgroundColor: backgroundColor,
    },
  });

  return (
    <Button {...buttonColorStyles} {...arrowButtonStyles} {...props}>
      {contents}
    </Button>
  );
});
