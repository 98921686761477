import React from 'react';
import { css, before, after } from 'glamor';
import { theme } from '../../theme';

export const BulletedText: React.SFC = ({ children }) => (
  <div
    {...css(
      {
        color: 'currentColor',
        marginBottom: '1rem',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 33,
      },
      before({
        position: 'absolute',
        left: 0,
        top: 3,
        borderRadius: '50%',
        content: '""',
        height: 11,
        width: 11,
        border: `1px solid`,
        borderColor: 'currentColor',
      })
    )}
  >
    {children}
  </div>
);
