import React from 'react';
import { theme } from '../../theme';

export const Nodes: React.SFC<{}> = () => (
  <svg
    width={700}
    height={750}
    viewBox="0 0 63 73"
    version="1.1"
    style={{
      opacity: 0.05,
    }}
  >
    <g
      transform="translate(3 3)"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M21.7,4.6 L56,47.1 L21.8,50.8 L15,66.5 L0.1,0.1 L41.1,8.3 L21.7,50.7 L21.7,4.6 Z"
        stroke={theme.color.grayLighter}
        strokeWidth="0.5"
      />
      <path
        d="M41.1,8.3 L5.5,30.2"
        stroke={theme.color.grayLighter}
        strokeWidth="0.5"
      />
      <circle fill={theme.color.grayLighter} cx="41" cy="9" r="2" />
      <circle fill={theme.color.grayLighter} cx="22" cy="5" r="2" />
      <circle fill={theme.color.grayLighter} cx="22" cy="51" r="2" />
      <circle fill={theme.color.grayLighter} cx="7" cy="30" r="2" />
    </g>
  </svg>
);
