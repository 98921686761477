export enum Threshold {
  HIGH = 'high-threshold',
  MEDIUM = 'medium-threshold',
  LOW = 'low-threshold',
}

export interface Logo {
  id: string;
  name: string;
  url: string;
  threshold: Threshold;
}

export const logos: Logo[] = [
  {
    id: 'abc',
    name: 'ABC',
    url: 'https://www.abc.go.com',
    threshold: Threshold.HIGH,
  },
  {
    id: 'adobe',
    name: 'Adobe',
    url: 'https://www.adobe.com',
    threshold: Threshold.MEDIUM,
  },
  {
    id: 'amc-networks',
    name: 'AMC Networks',
    url: 'https://www.amcnetworks.com',
    threshold: Threshold.HIGH,
  },
  {
    id: 'american-airlines',
    name: 'American Airlines',
    url: 'https://www.aa.com',
    threshold: Threshold.LOW,
  },
  {
    id: 'aptiv',
    name: 'Aptiv',
    url: 'https://www.aptiv.com',
    threshold: Threshold.HIGH,
  },
  {
    id: 'astrazeneca',
    name: 'AstraZeneca',
    url: 'https://www.astrazeneca.com',
    threshold: Threshold.HIGH,
  },
  {
    id: 'cbs',
    name: 'CBS',
    url: 'https://www.cbs.com/',
    threshold: Threshold.HIGH,
  },
  {
    id: 'cisco',
    name: 'Cisco',
    url: 'https://www.cisco.com',
    threshold: Threshold.HIGH,
  },
  {
    id: 'coca-cola',
    name: 'Coca-Cola',
    url: 'https://www.coca-cola.com',
    threshold: Threshold.HIGH,
  },
  {
    id: 'comcast-nbc-universal',
    name: 'Comcast-NBCUniversal',
    url: 'https://corporate.comcast.com/company/nbcuniversal',
    threshold: Threshold.LOW,
  },
  {
    id: 'conde-nast',
    name: 'Condé Nast',
    url: 'https://www.condenast.com',
    threshold: Threshold.HIGH,
  },
  {
    id: 'cox',
    name: 'Cox',
    url: 'https://www.cox.com',
    threshold: Threshold.HIGH,
  },
  {
    id: 'delphi',
    name: 'Delphi',
    url: 'https://www.delphi.com',
    threshold: Threshold.HIGH,
  },
  {
    id: 'delta',
    name: 'Delta',
    url: 'https://www.delta.com',
    threshold: Threshold.HIGH,
  },
  {
    id: 'discovery',
    name: 'Discovery',
    url: 'https://corporate.discovery.com',
    threshold: Threshold.HIGH,
  },
  {
    id: 'disney',
    name: 'Disney',
    url: 'https://www.disney.com',
    threshold: Threshold.HIGH,
  },
  {
    id: 'dolby-digital',
    name: 'Dolby Digital',
    url: 'https://www.dolby.com',
    threshold: Threshold.HIGH,
  },
  {
    id: 'espn',
    name: 'ESPN',
    url: 'https://www.espn.com',
    threshold: Threshold.HIGH,
  },
  {
    id: 'facebook',
    name: 'Facebook',
    url: 'https://www.facebook.com',
    threshold: Threshold.MEDIUM,
  },
  {
    id: 'ford',
    name: 'Ford',
    url: 'https://www.ford.com',
    threshold: Threshold.MEDIUM,
  },
  {
    id: 'fox',
    name: 'Fox',
    url: 'https://www.fox.com/',
    threshold: Threshold.HIGH,
  },
  {
    id: 'ge',
    name: 'General Electric',
    url: 'https://www.ge.com/',
    threshold: Threshold.MEDIUM,
  },
  {
    id: 'gibson',
    name: 'Gibson',
    url: 'https://www.gibson.com',
    threshold: Threshold.HIGH,
  },
  {
    id: 'google',
    name: 'Google',
    url: 'https://www.google.com',
    threshold: Threshold.HIGH,
  },
  {
    id: 'grant-thornton',
    name: 'Grant Thornton',
    url: 'https://www.grantthornton.com',
    threshold: Threshold.HIGH,
  },
  {
    id: 'gsk',
    name: 'GSK',
    url: 'https://www.gsk.com/',
    threshold: Threshold.LOW,
  },
  {
    id: 'hp',
    name: 'Hewlett-Packard',
    url: 'https://www.hp.com',
    threshold: Threshold.HIGH,
  },
  {
    id: 'hyundai',
    name: 'Hyundai',
    url: 'https://www.hyundai.com',
    threshold: Threshold.HIGH,
  },
  {
    id: 'iheart-media',
    name: 'iHeart Media',
    url: 'https://www.iheartmedia.com',
    threshold: Threshold.HIGH,
  },
  {
    id: 'ion-television',
    name: 'Ion Television',
    url: 'https://iontelevision.com',
    threshold: Threshold.HIGH,
  },
  {
    id: 'johnson-and-johnson',
    name: 'Johnson and Johnson',
    url: 'https://www.jnj.com',
    threshold: Threshold.HIGH,
  },
  {
    id: 'kpmg',
    name: 'KPMG',
    url: 'https://www.kpmg.com',
    threshold: Threshold.MEDIUM,
  },
  {
    id: 'level-three',
    name: 'Level 3 Communications',
    url: 'https://www.level3.com',
    threshold: Threshold.HIGH,
  },
  {
    id: 'lockheed-martin',
    name: 'Lockheed Martin',
    url: 'https://www.lockheedmartin.com',
    threshold: Threshold.HIGH,
  },
  {
    id: 'loreal',
    name: `L'Oréal`,
    url: 'https://www.loreal.com',
    threshold: Threshold.HIGH,
  },
  {
    id: 'lpga',
    name: 'LPGA',
    url: 'http://www.lpga.com',
    threshold: Threshold.MEDIUM,
  },
  {
    id: 'marriott',
    name: 'Marriott',
    url: 'https://www.marriott.com',
    threshold: Threshold.HIGH,
  },
  {
    id: 'mcdonalds',
    name: `McDonald's`,
    url: 'https://www.mcdonalds.com',
    threshold: Threshold.LOW,
  },
  {
    id: 'mercedes-benz',
    name: 'Mercedes-Benz',
    url: 'https://www.mercedes-benz.com',
    threshold: Threshold.HIGH,
  },
  {
    id: 'microsoft',
    name: 'Microsoft',
    url: 'https://www.microsoft.com',
    threshold: Threshold.HIGH,
  },
  {
    id: 'mitsubishi',
    name: 'Mitsubishi',
    url: 'https://www.mitsubishicars.com',
    threshold: Threshold.HIGH,
  },
  {
    id: 'mtv',
    name: 'MTV',
    url: 'https://www.mtv.com',
    threshold: Threshold.MEDIUM,
  },
  {
    id: 'netflix',
    name: 'Netflix',
    url: 'https://www.netflix.com',
    threshold: Threshold.HIGH,
  },
  {
    id: 'nexstar',
    name: 'Nexstar',
    url: 'https://www.nexstar.tv',
    threshold: Threshold.HIGH,
  },
  {
    id: 'oakley',
    url: 'https://www.oakley.com',
    name: 'Oakley',
    threshold: Threshold.HIGH,
  },
  {
    id: 'optimum',
    name: 'Optimum',
    url: 'https://www.optimum.net',
    threshold: Threshold.HIGH,
  },
  {
    id: 'paramount',
    name: 'Paramount',
    url: 'https://www.paramount.com',
    threshold: Threshold.HIGH,
  },
  {
    id: 'paypal',
    name: 'PayPal',
    url: 'https://www.paypal.com',
    threshold: Threshold.HIGH,
  },
  {
    id: 'pepsi',
    name: 'Pepsi',
    url: 'https://www.pepsi.com',
    threshold: Threshold.MEDIUM,
  },
  {
    id: 'plastic-omnium',
    name: 'Plastic Omnium',
    url: 'https://plasticomnium.com',
    threshold: Threshold.HIGH,
  },
  {
    id: 'procter-and-gamble',
    name: 'Procter and Gamble',
    url: 'https://www.pg.com',
    threshold: Threshold.HIGH,
  },
  {
    id: 'pwc',
    name: 'PwC',
    url: 'https://www.pwc.com',
    threshold: Threshold.HIGH,
  },
  {
    id: 'reckitt-benckiser',
    name: 'Reckitt Benckiser',
    url: 'https://www.rb.com',
    threshold: Threshold.MEDIUM,
  },
  {
    id: 'salesforce',
    name: 'Salesforce',
    url: 'https://www.salesforce.com',
    threshold: Threshold.MEDIUM,
  },
  {
    id: 'samsung',
    name: 'Samsung',
    url: 'https://www.samsung.com',
    threshold: Threshold.MEDIUM,
  },
  {
    id: 'schneider-electric',
    name: 'Schneider Electric',
    url: 'https://www.schneider-electric.com',
    threshold: Threshold.HIGH,
  },
  {
    id: 'sony-music',
    name: 'Sony Music',
    url: 'https://www.sonymusic.com',
    threshold: Threshold.HIGH,
  },
  {
    id: 'sony-pictures',
    name: 'Sony Pictures',
    url: 'https://www.sonypictures.com',
    threshold: Threshold.MEDIUM,
  },
  {
    id: 'spectrum',
    name: 'Spectrum',
    url: 'https://www.spectrum.com',
    threshold: Threshold.HIGH,
  },
  {
    id: 'tata',
    name: 'Tata',
    url: 'http://www.tata.com',
    threshold: Threshold.HIGH,
  },
  {
    id: 'the-cw',
    name: 'The CW',
    url: 'http://www.cwtv.com',
    threshold: Threshold.HIGH,
  },
  {
    id: 'time-inc',
    name: 'Time Inc.',
    url: 'https://www.timeinc.com',
    threshold: Threshold.HIGH,
  },
  {
    id: 'travelers',
    name: 'Travelers',
    url: 'https://www.travelers.com',
    threshold: Threshold.HIGH,
  },
  {
    id: 'turner',
    name: 'Turner',
    url: 'https://www.turner.com',
    threshold: Threshold.HIGH,
  },
  {
    id: 'twenty-first-century-fox',
    name: '21st Century Fox',
    url: 'https://www.21cf.com',
    threshold: Threshold.HIGH,
  },
  {
    id: 'unilever',
    name: 'Unilever',
    url: 'https://www.unilever.com',
    threshold: Threshold.HIGH,
  },
  {
    id: 'us-army',
    name: 'US Army',
    url: 'https://www.goarmy.com',
    threshold: Threshold.MEDIUM,
  },
  {
    id: 'verizon',
    name: 'Verizon',
    url: 'https://www.verizon.com',
    threshold: Threshold.HIGH,
  },
  {
    id: 'viacom',
    name: 'Viacom',
    url: 'https://www.viacom.com',
    threshold: Threshold.HIGH,
  },
  {
    id: 'warner-brothers',
    name: 'Warner Brothers',
    url: 'https://www.warnerbros.com',
    threshold: Threshold.HIGH,
  },
  {
    id: 'wawa',
    name: 'Wawa',
    url: 'https://www.wawa.com',
    threshold: Threshold.HIGH,
  },
];
