import * as React from 'react';
import { withPrefix } from 'gatsby';
import { ProgressiveImage, ProgressiveImageProps } from './ProgressiveImage';
import { css } from 'glamor';

export interface LazyImageProps {
  height?: number;
  width?: number;
  alt: string;
  className?: string;
  src?: string;
  style?: object;
}

export class LazyImage extends React.Component<LazyImageProps, {}> {
  img: any;

  render() {
    const { height, width, src, alt, className, style } = this.props;

    return (
      <ProgressiveImage
        src={withPrefix(src)}
        placeholder={
          'data:image/gif;base64,R0lGODlhAQABAPABAP///wAAACH5BAEKAAAALAAAAAABAAEAAAICRAEAOw%3D%3D'
        }
      >
        {({ image, isLoading }) => (
          <img
            {...css([
              { opacity: 0.01 },
              !isLoading && {
                opacity: 1,
                transition: 'opacity .2s ease-out',
              },
            ])}
            src={image}
            alt={alt}
            width={width}
            style={style}
            height={height}
          />
        )}
      </ProgressiveImage>
    );
  }
}
