import React from 'react';
import { theme } from '../../theme';

export const Nucleus: React.SFC<{}> = () => (
  <svg
    style={{
      opacity: 0.05,
      height: 800,
      width: 800,
    }}
    viewBox="0 0 68 68"
    version="1.1"
  >
    <g
      transform="translate(3 3)"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M23.9,38.7 C33.2,47.6 44.1,51.5 48.1,47.3 C52.2,43.2 48,32.6 38.7,23.7 C29.4,14.8 18.5,10.9 14.5,15.1 C10.4,19.3 14.6,29.8 23.9,38.7 L23.9,38.7 Z"
        stroke={theme.color.grayLighter}
        strokeWidth="0.75"
      />
      <path
        d="M38.7,38.7 C29.4,47.6 18.5,51.5 14.5,47.3 C10.4,43.2 14.6,32.6 23.9,23.7 C33.2,14.8 44.1,10.9 48.1,15.1 C52.3,19.3 48,29.8 38.7,38.7 L38.7,38.7 Z"
        stroke={theme.color.grayLighter}
        strokeWidth="0.75"
      />
      <ellipse
        stroke={theme.color.grayLighter}
        strokeWidth="0.75"
        cx="31.6"
        cy="31.2"
        rx="10.6"
        ry="31.2"
      />
      <ellipse
        stroke={theme.color.grayLighter}
        strokeWidth="0.75"
        cx="31.2"
        cy="31.6"
        rx="31.2"
        ry="10.6"
      />
      <circle fill={theme.color.grayLighter} cx="25" cy="56" r="3" />
      <circle fill={theme.color.grayLighter} cx="38" cy="7" r="3" />
      <circle fill={theme.color.grayLighter} cx="7" cy="38" r="3" />
    </g>
  </svg>
);
