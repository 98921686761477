import React from 'react';
import { keyframes, StyleProps } from 'glamor';
import { splitArray } from '../../utils/splitArray';
import { logos } from './logos';
import { Client } from './Client';
import { Filters } from './Filters';
import { theme } from '../../theme';

const slide = keyframes({
  from: {
    transform: 'translate3d(0,0,0)',
  },
  to: {
    transform: 'translate3d(-50%,0,0)',
  },
});

export const ClientsMarquee: React.SFC<StyleProps> = props => {
  const [firstLogos, secondLogos] = splitArray(logos, 2);

  return (
    <div {...props}>
      <div
        css={{
          display: 'inline-block',
          whiteSpace: 'nowrap',
          animation: `${slide} 200s linear 0s infinite normal none running`,
        }}
      >
        {firstLogos.map(({ id, name, url }) => (
          <Client
            key={id}
            id={id}
            name={name}
            url={url}
            css={{
              margin: '1rem 1.5rem',
              [theme.media.medium]: { margin: '1rem 3em' },
            }}
          />
        ))}
        {firstLogos.map(({ id, name, url }) => (
          <Client
            id={id}
            key={`${id}-repeat}`}
            name={name}
            url={url}
            css={{
              margin: '1rem 1.5rem',
              [theme.media.medium]: { margin: '1rem 3em' },
            }}
          />
        ))}
      </div>
      <div
        css={{
          display: 'inline-block',
          whiteSpace: 'nowrap',
          animation: `${slide} 300s linear 0s infinite normal none running`,
        }}
      >
        {secondLogos.map(({ id, name, url }) => (
          <Client
            key={id}
            id={id}
            name={name}
            url={url}
            css={{
              margin: '1rem 1.5rem',
              [theme.media.medium]: { margin: '1rem 3em' },
            }}
          />
        ))}
        {secondLogos.map(({ id, name, url }) => (
          <Client
            key={`${id}-repeat}`}
            id={id}
            name={name}
            url={url}
            css={{
              margin: '1rem 1.5rem',
              [theme.media.medium]: { margin: '1rem 3em' },
            }}
          />
        ))}
      </div>
    </div>
  );
};

(ClientsMarquee as any).displayName = 'ClientsMarquee';
