import React from 'react';
import { Link } from 'gatsby';
import { theme } from '../../theme';
import { css, keyframes, StyleProps } from 'glamor';
import { LinkProps } from '@reach/router';

const sharedButtonStyles = css({
  position: 'relative',
  padding: '.75rem 1.5rem .75rem 1.5rem',
  fontSize: '.8rem',
  width: 'auto',
  textAlign: 'center',
  fontWeight: theme.bold,
  textTransform: 'uppercase',
  letterSpacing: '.1em',
  whiteSpace: 'nowrap',
  cursor: 'pointer',
  WebkitAppearance: 'none',
  display: 'inline-block',
  textDecoration: 'none',
  '&:hover': {
    backgroundColor: theme.color.gray,
  },
});

export type ButtonProps = {
  backgroundColor?: string;
  color?: string;
  href?: string;
  to?: string;
} & StyleProps &
  LinkProps<{}> &
  React.HTMLAttributes<HTMLAnchorElement> &
  React.HTMLAttributes<HTMLButtonElement>;

export const Button = React.forwardRef<{}, ButtonProps>(
  (
    {
      backgroundColor = theme.color.grayDarker,
      color = theme.color.white,
      href,
      to,
      children,
      ...rest
    },
    ref
  ) => {
    const buttonColorStyles = css({
      color: color,
      background: backgroundColor,
      border: '2px solid transparent',
      transition: 'background 200ms ease-in-out',
    });

    if (href) {
      return (
        <a
          href={href}
          {...sharedButtonStyles}
          {...buttonColorStyles}
          {...rest}
          ref={ref as React.Ref<HTMLAnchorElement>}
        >
          {children}
        </a>
      );
    }

    if (to) {
      return (
        <Link
          to={to}
          {...sharedButtonStyles}
          {...buttonColorStyles}
          {...rest}
          ref={ref as React.RefObject<any>}
        >
          {children}
        </Link>
      );
    }

    return (
      <button
        {...sharedButtonStyles}
        {...buttonColorStyles}
        {...rest}
        ref={ref as React.RefObject<HTMLButtonElement>}
      >
        {children}
      </button>
    );
  }
);
