import React from 'react';
import { theme } from '../../theme';

export const Code: React.SFC<{}> = () => (
  <svg
    width={800}
    viewBox="-100 100 1898 1269"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      opacity: 0.05,
    }}
  >
    <g id="Code" transform="translate(0 233)" fill={theme.color.grayLighter}>
      <g transform="translate(0 87)">
        <rect x="550" width="100" height="25" rx="12.5" />
        <rect x="1525" width="50" height="25" rx="12.5" />
        <rect x="1100" width="400" height="25" rx="12.5" />
        <rect x="675" width="400" height="25" rx="12.5" />
        <rect width="300" height="25" rx="12.5" />
        <rect x="325" width="200" height="25" rx="12.5" />
      </g>
      <g id="Group-8" transform="translate(0 173)">
        <rect x="1075" width="350" height="25" rx="12.5" />
        <rect x="525" width="300" height="25" rx="12.5" />
        <rect width="500" height="25" rx="12.5" />
        <rect x="850" width="200" height="25" rx="12.5" />
      </g>
      <g transform="translate(250)">
        <rect width="250" height="25" rx="12.5" />
        <rect x="275" width="100" height="25" rx="12.5" />
        <rect x="400" width="400" height="25" rx="12.5" />
        <rect x="1050" width="275" height="25" rx="12.5" />
        <rect x="825" width="200" height="25" rx="12.5" />
      </g>
      <g transform="translate(0 260)">
        <rect width="500" height="25" rx="12.5" />
        <rect x="525" width="100" height="25" rx="12.5" />
        <rect x="650" width="400" height="25" rx="12.5" />
        <rect x="1300" width="275" height="25" rx="12.5" />
        <rect x="1075" width="200" height="25" rx="12.5" />
      </g>
      <g transform="translate(0 433)">
        <rect width="500" height="25" rx="12.5" />
        <rect x="525" width="100" height="25" rx="12.5" />
        <rect x="650" width="400" height="25" rx="12.5" />
        <rect x="1300" width="275" height="25" rx="12.5" />
        <rect x="1075" width="200" height="25" rx="12.5" />
      </g>
      <g transform="translate(0 520)">
        <rect x="550" width="100" height="25" rx="12.5" />
        <rect x="1525" width="50" height="25" rx="12.5" />
        <rect x="1100" width="400" height="25" rx="12.5" />
        <rect x="675" width="400" height="25" rx="12.5" />
        <rect width="300" height="25" rx="12.5" />
        <rect x="325" width="200" height="25" rx="12.5" />
      </g>
      <g transform="translate(0 347)">
        <rect x="550" width="100" height="25" rx="12.5" />
        <rect x="1525" width="50" height="25" rx="12.5" />
        <rect x="1100" width="400" height="25" rx="12.5" />
        <rect x="675" width="400" height="25" rx="12.5" />
        <rect width="300" height="25" rx="12.5" />
        <rect x="325" width="200" height="25" rx="12.5" />
      </g>
      <g transform="translate(0 173)">
        <rect x="1075" width="350" height="25" rx="12.5" />
        <rect x="525" width="300" height="25" rx="12.5" />
        <rect width="500" height="25" rx="12.5" />
        <rect x="850" width="200" height="25" rx="12.5" />
      </g>
      <g transform="translate(0 87)">
        <rect x="550" width="100" height="25" rx="12.5" />
        <rect x="1525" width="50" height="25" rx="12.5" />
        <rect x="1100" width="400" height="25" rx="12.5" />
        <rect x="675" width="400" height="25" rx="12.5" />
        <rect width="300" height="25" rx="12.5" />
        <rect x="325" width="200" height="25" rx="12.5" />
      </g>
      <g transform="translate(0 607)">
        <rect x="550" width="100" height="25" rx="12.5" />
        <rect x="1525" width="50" height="25" rx="12.5" />
        <rect x="1100" width="400" height="25" rx="12.5" />
        <rect x="675" width="400" height="25" rx="12.5" />
        <rect width="300" height="25" rx="12.5" />
        <rect x="325" width="200" height="25" rx="12.5" />
      </g>
      <g transform="translate(250 774)">
        <rect width="250" height="25" rx="12.5" />
        <rect x="275" width="100" height="25" rx="12.5" />
        <rect x="400" width="400" height="25" rx="12.5" />
        <rect x="1050" width="275" height="25" rx="12.5" />
        <rect x="825" width="200" height="25" rx="12.5" />
      </g>
      <g id="Group-6" transform="translate(0 858)">
        <rect x="550" width="100" height="25" rx="12.5" />
        <rect x="1525" width="50" height="25" rx="12.5" />
        <rect x="1100" width="400" height="25" rx="12.5" />
        <rect x="675" width="400" height="25" rx="12.5" />
        <rect width="300" height="25" rx="12.5" />
        <rect x="325" width="200" height="25" rx="12.5" />
      </g>
      <g transform="translate(0 945)">
        <rect width="500" height="25" rx="12.5" />
        <rect x="525" width="100" height="25" rx="12.5" />
        <rect x="650" width="400" height="25" rx="12.5" />
        <rect x="1300" width="275" height="25" rx="12.5" />
        <rect x="1075" width="200" height="25" rx="12.5" />
      </g>
      <g transform="translate(0 1032)">
        <rect x="1075" width="350" height="25" rx="12.5" />
        <rect x="525" width="300" height="25" rx="12.5" />
        <rect width="500" height="25" rx="12.5" />
        <rect x="850" width="200" height="25" rx="12.5" />
      </g>
    </g>
  </svg>
);
