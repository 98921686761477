import React from 'react';
import { theme } from '../theme';
import { Footer } from '../components/Footer';
import { ArrowButton } from '../components/Button/ArrowButton';
import { AccentHeading } from '../components/Text/AccentHeading';
import { BulletedText } from '../components/Text/BulletedText';
import { Container } from '../components/Container';
import { Nucleus } from '../components/Logos/Nucleus';
import { Nodes } from '../components/Logos/Nodes';
import { Heading } from '../components/Text/Heading';
import { Text } from '../components/Text/Text';
import { ClientsMarquee } from '../components/Clients/ClientsMarquee';
import { Layout } from '../layouts';
import logo from '../images/logo.png';
import { Code } from '../components/Logos/Code';
import { Helmet } from 'react-helmet';

const containerSmall = 800;

const structuredData = JSON.stringify({
  '@context': 'http://schema.org',
  '@type': 'Organization',
  name: 'The Palmer Group',
  url: 'http://palmer.net',
  // we could instead add logo on homepage and use itemprop="logo"
  // eg
  // <div itemscope itemtype="http://schema.org/Organization">
  //  <img itemprop="logo" src="logo.svg" alt="ACME Inc." />
  // </div>
  logo: 'https://palmer.net' + logo,
  address: {
    '@type': 'PostalAddress',
    streetAddress: '33 Irving Pl',
    addressLocality: 'New York',
    addressRegion: 'NY',
    postalCode: '10003',
    addressCountry: 'USA',
  },
  sameAs: [
    'http://www.twitter.com/palmergrouphq',
    'http://www.linkedin.com/company/the-palmer-group/',
    'http://www.github.com/palmerhq',
  ],
});

export default class Index extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet
          meta={[
            {
              name: 'description',
              content:
                'The Palmer Group is a Strategy, Design, and Engineering Firm.',
            },
            { property: 'og:title', content: 'The Palmer Group' },
            { name: 'twitter:title', content: 'The Palmer Group' },
          ]}
        >
          <script type="application/ld+json">{structuredData}</script>
        </Helmet>
        <div
          css={{
            marginTop: -80,
            display: 'relative',
            paddingTop: 80,
            backgroundImage: `radial-gradient(circle, #D7D7D7, #D7D7D7 1px, #FFF 1px, #FFF)`,
            backgroundSize: `28px 28px`,
          }}
        >
          <Container padding={['all']} size="large">
            <div
              css={{
                margin: '-80px auto 2rem',
                display: 'relative',
                maxWidth: containerSmall,
              }}
            >
              <AccentHeading>The Palmer Group</AccentHeading>
              <Heading
                component="h1"
                size={0}
                css={{
                  [theme.media.large]: {
                    fontSize: 80,
                  },
                  marginBottom: '1rem',
                }}
              >
                Building the future.
              </Heading>
              <Text
                size={0}
                css={{
                  [theme.media.large]: {
                    fontSize: 40,
                  },
                  marginBottom: '2rem',
                }}
              >
                We are a strategy, design, and engineering firm.
              </Text>
              <ArrowButton to="/contact">Work with us</ArrowButton>
            </div>
          </Container>
        </div>

        <Container padding={['all']} size="large" background="grayDarker">
          <div
            css={{
              margin: '0 auto',
              position: 'relative',
              maxWidth: containerSmall,
              color: theme.color.grayLighter,
            }}
          >
            <div
              css={{
                position: 'absolute',
                top: -150,
                right: 180,
                width: '100%',
              }}
            >
              <Nucleus />
            </div>
            <div css={{ position: 'relative' }}>
              <AccentHeading color="white">What We Do</AccentHeading>
              <Heading
                component="h2"
                color="white"
                size={1}
                css={{ marginBottom: '1rem' }}
              >
                Strategy. Design. Engineering.
              </Heading>
              <Text
                size={0}
                css={{
                  marginBottom: '2rem',
                }}
              >
                The Palmer Group transforms businesses by building best-in-class
                data-driven experiences, products, and workflows rooted in
                strong creative, technology, and organizational strategy. Our
                multi-disciplinary team of analysts, designers, and engineers
                has been helping our clients solve problems for over 40 years.
              </Text>
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginBottom: '1rem',
                  [theme.media.medium]: {
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  },
                }}
              >
                <div
                  css={{
                    marginBottom: '1rem',
                  }}
                >
                  <Heading
                    color="white"
                    css={{ marginBottom: '1rem' }}
                    size={2}
                  >
                    Strategy
                  </Heading>
                  {[
                    'Growth Strategy',
                    'Digital Transformation',
                    'Data Science',
                    'AI/ML Strategy',
                    'Automation',
                    'Privacy & Security',
                    'Business Consulting',
                    'Workshops & Conferences',
                  ].map(i => (
                    <BulletedText key={i}>{i}</BulletedText>
                  ))}
                </div>
                <div
                  css={{
                    marginBottom: '1rem',
                  }}
                >
                  <Heading
                    color="white"
                    css={{ marginBottom: '1rem' }}
                    size={2}
                  >
                    Design
                  </Heading>
                  {[
                    'User Experience',
                    'Interactive Prototyping',
                    'Design Systems & Workflows',
                    'Data Visualization',
                    'Visual Design',
                    'Product Design',
                    'Branding & Positioning',
                  ].map(i => (
                    <BulletedText key={i}>{i}</BulletedText>
                  ))}
                </div>

                <div
                  css={{
                    marginBottom: '1rem',
                  }}
                >
                  <Heading
                    color="white"
                    css={{ marginBottom: '1rem' }}
                    size={2}
                  >
                    Engineering
                  </Heading>
                  {[
                    'Frontend Engineering',
                    'Backend Engineering',
                    'Performance Auditing',
                    'Developer Tooling & Automation',
                    'Cloud Infrastructure & DevOps',
                    'Systems Architecture',
                    'AI/ML Pipelines',
                  ].map(i => (
                    <BulletedText key={i}>{i}</BulletedText>
                  ))}
                </div>
              </div>
              <ArrowButton
                to="/contact"
                backgroundColor={theme.color.green}
                color={theme.color.grayDarker}
              >
                Get in touch
              </ArrowButton>
            </div>
          </div>
        </Container>

        <Container padding={['all']} size="large" background="offWhiteLightest">
          <div
            css={{
              margin: '0 auto',
              position: 'relative',
              maxWidth: containerSmall,
            }}
          >
            <div
              css={{
                position: 'absolute',
                top: -150,
                left: -180,
                width: '100%',
              }}
            >
              <Nodes />
            </div>
            <div css={{ position: 'relative' }}>
              <AccentHeading>Our Process</AccentHeading>
              <Heading component="h2" size={1} css={{ marginBottom: '1rem' }}>
                Small teams. Huge results.
              </Heading>
              <Text size={0} css={{ marginBottom: '2rem' }}>
                Small, adaptive, interdisciplinary teams work in close
                collaboration with our clients. Our user-centric approach
                focuses on creating a seamless experience across all digital
                touchpoints. By leveraging a combination of open source
                software, 3rd-party solutions, and proprietary tools, we deliver
                effective, sustainable, and performant results that drive value.
              </Text>
              <Heading
                size={2}
                css={{
                  marginBottom: '1rem',
                }}
              >
                How We Work
              </Heading>
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  [theme.media.medium]: {
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                  },
                }}
              >
                {[
                  'In small teams',
                  'In weekly iterations of focused work',
                  'At a sustainable pace',
                  'Flexibly based on project needs',
                  'With open source and proprietary tools',
                  'On new or existing projects and products',
                ].map(i => (
                  <div
                    key={i}
                    css={{
                      color: theme.color.grayDarker,
                      marginBottom: '1rem',
                      [theme.media.medium]: {
                        width: '50%',
                      },
                    }}
                  >
                    {i}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Container>

        <Container padding={['all']} size="large" background="grayDarker">
          <div
            css={{
              margin: '0 auto',
              position: 'relative',
              maxWidth: containerSmall,
              color: theme.color.grayLighter,
            }}
          >
            <div
              css={{
                position: 'absolute',
                top: -150,
                left: -150,
                width: '100%',
              }}
            >
              <Code />
            </div>
            <div css={{ position: 'relative' }}>
              <AccentHeading color="white">Our Tools</AccentHeading>
              <Heading
                component="h2"
                size={1}
                color="white"
                css={{ marginBottom: '1rem' }}
              >
                Open Source Software
              </Heading>
              <Text css={{ marginBottom: '2rem' }} size={0}>
                Open Source plays a major role in how we build products and
                solutions. We give back to our community by creating valuable,
                free, and easy-to-use software and tools.
              </Text>
              <ArrowButton
                to="/open-source"
                backgroundColor={theme.color.green}
                color={theme.color.grayDarker}
              >
                Explore our projects
              </ArrowButton>
            </div>
          </div>
        </Container>

        <Container padding={['all']} size="large" background="offWhiteLightest">
          <div
            css={{
              margin: '0 auto',
              position: 'relative',
              maxWidth: containerSmall,
            }}
          >
            <AccentHeading>Who We Work With</AccentHeading>

            <Heading component="h2" size={1} css={{ marginBottom: '1rem' }}>
              Clients &amp; Partners
            </Heading>
            <Text css={{ marginBottom: '2rem' }} size={0}>
              We feel privileged to work with some extraordinary organizations.
            </Text>
            <div css={{ transform: 'translateX(-150%)' }}>
              <ClientsMarquee />
            </div>
          </div>
        </Container>
        <div
          css={{
            margin: '0 auto',
            padding: '4rem 2rem',
            textAlign: 'center',
            position: 'relative',
            background: theme.color.white,
            [theme.media.medium]: {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            },
          }}
        >
          <Heading size={2}>Got a project to discuss?</Heading>
          <div
            css={{
              marginTop: '2rem',
              [theme.media.medium]: {
                marginTop: 0,
                marginLeft: '2rem',
              },
            }}
          >
            <ArrowButton to="/contact">Contact Us</ArrowButton>
          </div>
        </div>
        <Footer />
      </Layout>
    );
  }
}
