export function splitArray(array: any[], numberOfSplits: number) {
  const splitLength = array.length / numberOfSplits;
  const splits = [];

  for (let i = 0; i < numberOfSplits; i += 1) {
    splits.push(array.slice(i * splitLength, (i + 1) * splitLength));
  }

  return splits;
}
